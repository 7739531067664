* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    //   font-weight: 300;
}

body {
    background-color: whitesmoke;

    #MyCv {
        margin: 0 auto;

        header {
            background-color: orangered;
            position: sticky;
            top: 0;
            z-index: 3;
            margin-bottom: 10px;

            div.content {
                width: 1140px;
                margin: 0 auto;
                padding: 5px 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;

                h3 {
                    color: white;

                    i {
                        cursor: pointer;
                    }
                }

                div {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    button {
                        background-color: white;
                        color: orangered;
                        padding: 0px 5px;
                        border: none;
                        border-radius: 6px;
                        font-size: 16px;
                        font-weight: 600;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        // line-height: 20px;
                        cursor: pointer;

                        i {
                            transform: rotate(-120deg);
                            color: orangered;
                            // font-size: 16px;
                            font-weight: 600;
                            cursor: pointer;
                            transition: all 0.3s;
                        }

                        &.nhanLiXi {
                            font-size: 16px;
                            width: 100px;
                        }
                    }
                }
            }
        }

        main {
            width: 1140px;
            margin: 0 auto 10px;
            padding: 10px 40px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            //   border-radius: 0px 20px 0px 20px;
            border-left: 3px solid transparent;
            transition: all 0.5s;

            //   cursor: pointer;
            &:hover {
                border-left: 3px solid orangered;
            }

            .title {
                h2 {
                    i {
                        // width: 30px;
                        // text-align: center;
                    }

                    font-size: 22px;
                    color: orangered;
                }
            }

            .info {
                width: 100%;
                margin: 20px auto 0;
                display: flex;
                align-items: center;
                gap: 30px;
                position: relative;

                .photo {
                    width: 200px;
                    height: 200px;
                    //   background-color: red;
                    //   border: 1px solid silver;
                    border-radius: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    img {
                        width: 200px;
                        height: 200px;
                        border-radius: 5px;
                        object-fit: cover;
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                    }

                    .qrCode {
                        position: absolute;
                        top: 0px;
                        right: 0;
                        z-index: 2;
                        background-color: whitesmoke;
                    }
                }

                .text {
                    width: 100%;
                    font-size: 20px;

                    .textFlex {
                        margin-top: 5px;
                        margin-bottom: 5px;
                        display: flex;
                        gap: 0px;

                        p.info {
                            width: 30px;
                            margin: 0;

                            i {
                                font-size: 18px;
                                color: rgb(130, 130, 130);
                            }
                        }

                        h4 {
                            width: 100%;
                            font-weight: 400;

                            a {
                                color: black;
                                text-decoration: none;

                                &:hover {
                                    color: royalblue;
                                }
                            }
                        }
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 1.8;
                }
            }

            h3 {
                width: 200px;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: rgb(130, 130, 130);
                margin-top: 10px;
            }

            ul {
                margin-left: 60px;

                li {
                    .skills {
                        width: 100%;
                        margin: 20px auto;
                        display: flex;
                        gap: 10px;

                        .text {
                            width: 100%;
                            font-size: 18px;

                            .textFlex {
                                margin-bottom: 3px;
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                p {
                                    width: 140px;
                                    // padding-top: 3px;
                                }

                                h4 {
                                    width: 100%;
                                    font-weight: 400;
                                    line-height: 1.6;

                                    a {
                                        color: black;
                                        text-decoration: none;

                                        &:hover {
                                            color: royalblue;
                                        }
                                    }

                                    a.link {
                                        color: royalblue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        footer {
            width: 1140px;
            margin: 20px auto;
            padding: 20px 40px 0;
            text-align: center;

            span {
                text-align: center;
                color: silver;
                font-size: 13px;
                font-weight: 400;
            }

            p {
                font-size: 12px;
                color: silver;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 103;
        background-color: rgba(0, 0, 0, 0.9);
    }

    #formLogin {
        width: 360px;
        margin: 0 auto;
        padding: 10px;
        background-color: white;
        position: fixed;
        top: -100%;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 104;

        .inputItem {
            display: flex;
            align-items: center;
            margin: 5px 0;
            border-bottom: 1px solid silver;
            display: flex;
            align-items: center;
            gap: 5px;

            input {
                width: 100%;
                height: 32px;
                padding: 0 3px;
                outline: none;
                border: none;
                font-size: 16px;
            }

            i {
                color: silver;

                &.key {
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: red;
                    }
                }
            }
        }

        button {
            width: 100%;
            height: 32px;
            margin-top: 5px;
            text-align: center;
            background-color: orangered;
            color: white;
            border: none;
            cursor: pointer;
            transition: all 0.3s;
            font-size: 16px;

            &:hover {
                background-color: red;
            }

            &.nutDong {
                margin-top: 20px;
                border-radius: 10px;
            }
        }

        &.trans0 {
            transition: all 0.3s;
            top: 20%;
            transform: translate(-50%, -50%);
        }

        &.liXiTrans0 {
            transition: all 0.3s;
            top: 36%;
            transform: translate(-50%, -50%);
        }

        //thông báo lì xì
        .contentLiXi {
            border: 2px solid red;
            border-radius: 10px;
            padding: 10px;

            // background-color: #ee1959;
            img {
                width: 75px;
                height: 75px;
                border-radius: 50%;
                position: absolute;
                top: 10px;
                left: 10px;
            }

            .qrCode {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                position: absolute;
                top: 75px;
                left: 7px;

                .ant-qrcode {
                    border: none;
                }
            }

            h5 {
                margin-bottom: 10px;
                color: red;
                font-size: 28px;
                font-family: "Lobster", sans-serif;
                font-weight: 100;
                text-align: right;
            }

            h3 {
                color: royalblue;
                font-family: "Lobster", sans-serif;
                font-weight: 100;
                font-size: 22px;
                margin-left: 64px;
            }

            h2 {
                color: royalblue;
                margin: 5px auto 0px;
                font-size: 42px;
                font-family: "Lobster", sans-serif;
                font-weight: 100;
                margin-left: 64px;
            }

            h4 {
                margin: 5px auto;
                color: red;
                font-size: 20px;

                p {
                    line-height: 30px;
                    font-family: "Lobster", sans-serif;
                    font-weight: 100;
                    color: red;

                    span {
                        text-transform: capitalize;
                        font-family: "Lobster", sans-serif;
                        font-weight: 100;
                        color: red;
                    }
                }
            }

            p {
                margin: 10px auto 0px;
                color: silver;
            }
        }
    }

    .custom-tooltip {
        .ant-tooltip-arrow {
            border-color: orangered;
        }
    }

    .backToTop {
        position: fixed;
        bottom: 87px;
        right: 20px;

        button {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50%;
            background-color: rgba(255, 68, 0, 0.8);
            font-size: 14px;
            font-weight: bold;
            color: white;
            cursor: pointer;

            // transition: all 0.3s;
            i {
                animation: fadeInUp 1s ease-in-out infinite;
            }
        }
    }

    .zaloChat {
        position: fixed;
        bottom: 40px;
        right: 20px;

        button {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50%;
            background-color: #015bd1c2;
            font-size: 14px;
            font-weight: bold;
            color: white;
            cursor: pointer;
            transition: all 0.3s;
            animation: zaloChatAnimation 2s infinite;
            /* 2s là thời gian mỗi chu kỳ, infinite để lặp lại vô hạn */
        }
    }

    .flip-container {
        perspective: 1000px;
        margin-left: 50px;
        cursor: pointer;

        .flip-card {
            width: 80px;
            // height: 300px;
            transform-style: preserve-3d;
            animation: flipAnimation 9s linear infinite;

            /* Chạy mỗi 5 giây và lặp vô hạn */
            .flip-card-inner {
                width: 100%;
                height: 100%;
                transform-style: preserve-3d;

                .flip-card-front,
                .flip-card-back {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    backface-visibility: hidden;
                }

                .flip-card-front {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 22px;
                    font-weight: 600;
                }

                .flip-card-back {
                    transform: rotateY(180deg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }
    }

    //lì xì
    .liXi {
        // position: fixed;
        // top: 0;
        // right: 0;
        // left: 0;
        // bottom: 0;
        min-height: 100vh;
        background-color: whitesmoke;
        z-index: 100;
        text-align: center;
        padding: 0 20px 20px;

        .topTitle {
            width: 400px;
            height: 150px;
            margin: -10px auto 0px;
            // background-image: url("../public/img/rongVang.png");
            background-size: contain;
            /* Có thể sử dụng 'contain' nếu bạn muốn hình ảnh hiển thị toàn bộ */
            background-position: center;
            /* Căn hình ảnh vào giữa div */
            background-repeat: no-repeat;
            /* Không lặp lại hình ảnh */
            position: relative;

            // background-color: royalblue;
            h3 {
                color: red;
                font-size: 30px;
                position: absolute;
                bottom: 0;
                width: 100%;
                font-family: "Lobster", sans-serif;
                font-weight: 300;

                // background: linear-gradient(to bottom, #3498db, #2980b9);
                // animation: wave 2s infinite alternate;
            }
        }

        button.back {
            position: absolute;
            top: 10px;
            right: 10px;
            color: white;
            background-color: orangered;
            width: 30px;
            height: 30px;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                background-color: red;
            }
        }

        .container {
            width: 1140px;
            margin: 0 auto;

            .content {
                h3.loiChucNamMoi {
                    margin: 10px auto 20px;
                    font-family: "Lobster", sans-serif;
                    font-weight: 300;
                    color: red;
                    font-size: 22px;
                    animation: blink 5s infinite;
                }

                form {
                    margin: 10px auto;
                    padding: 10px;
                    width: 100%;
                    background-color: white;
                    // border: 1px solid silver;
                    border-radius: 10px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

                    .inputItem {
                        margin: 5px 0;
                        border-bottom: 1px solid silver;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        i {
                            // display: block;
                            width: 20px;
                            text-align: center;
                        }

                        input {
                            width: 100%;
                            height: 34px;
                            outline: none;
                            border: none;
                            padding: 0 0 0 10px;
                            font-size: 16px;

                            &::placeholder {
                                // color: black;
                            }
                        }

                        select {
                            width: 100%;
                            height: 32px;
                            outline: none;
                            border: none;
                            // color: rgb(117, 114, 114);
                            font-size: 16px;
                            padding: 0 0 0 7px;

                            option {
                                height: 90px;
                            }
                        }
                    }

                    button {
                        margin: 10px auto;
                        height: 36px;
                        background-color: orangered;
                        color: white;
                        border: none;
                        border-radius: 10px;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        transition: all 0.3s;

                        &:hover {
                            background-color: red;
                        }
                    }
                }

                .audio {
                    width: 300px;
                    margin: 0 auto;
                    // display: none;
                }

                .listBaoLiXi {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin: 0 auto 30px;

                    .baoItem {
                        width: 180px;
                        height: 260px;
                        margin-top: 40px;
                        padding: 10px;
                        position: relative;

                        // cursor: pointer;
                        &:hover {
                            height: 260px;

                            .baoContent {
                                transform: translateY(-31px);

                                .hinhAnh {
                                    img {
                                        width: 140px;
                                        height: 140px;
                                    }
                                }
                            }
                        }

                        .baoContent {
                            text-align: center;
                            color: white;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: orangered;
                            z-index: 102;
                            transition: all 0.3s;
                            padding: 20px 10px;
                            border-radius: 10px 10px 0 0;

                            p {
                                width: 100%;
                                height: 40px;
                                font-size: 18px;
                                font-family: "Lobster", sans-serif;
                                // font-weight: 100;
                            }

                            .hinhAnh {
                                width: 140px;
                                height: 140px;
                                text-align: center;
                                margin: 20px auto 0;
                                display: flex;
                                align-items: center;

                                justify-content: center;

                                img {
                                    width: 120px;
                                    height: 120px;
                                    border-radius: 50%;
                                    transition: all 0.3s;

                                    &.latNguoc {
                                        transform: scaleX(-1);
                                    }
                                }
                            }

                            h3 {
                                position: absolute;
                                bottom: 10px;
                                left: 0;
                                right: 0;
                                color: white;
                                font-size: 28px;
                                text-align: center;
                                font-family: "Lobster", sans-serif;
                            }
                        }

                        button {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 30px;
                            z-index: 101;
                            background-color: orangered;
                            color: white;
                            border: none;
                            border-radius: 20px;
                            font-size: 16px;
                            font-weight: 600;
                            transition: all 0.3s;
                            cursor: pointer;

                            &:hover {
                                background-color: red;
                            }
                        }
                    }
                }

                .footer {
                    margin: 20px auto 10px;
                    background-color: rgb(216, 216, 216);
                    padding: 5px;

                    span {
                        color: rgb(250, 250, 250);
                    }
                }
            }
        }

        .danhSach {
            width: 100%;
            padding: 10px;
            margin: 10px auto;
            background-color: white;
            border-radius: 10px;

            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            table {
                width: 100%;
                margin: 10px auto 20px;
                border-collapse: collapse;
                border: none;

                thead {
                    tr {
                        font-weight: 500;

                        td {
                            text-align: left;
                            background-color: orangered;
                            color: white;
                            padding: 10px 5px;

                            &.ngayThang {
                                width: 100px;
                            }

                            &.hoVaTen {
                                // width: 30px;
                            }

                            &.baoLiXi {
                                // width: 100px;
                                text-align: right;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                gap: 10px;

                                .sort {
                                    margin-top: -5px;

                                    i {
                                        display: block;
                                        height: 10px;
                                        cursor: pointer;
                                    }
                                }
                            }

                            &.preview {
                                width: 30px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        cursor: pointer;
                        transition: all 0.3s;

                        &:nth-child(even) {
                            background-color: rgba(240, 240, 240, 0.6);
                        }

                        &:hover {
                            background-color: rgb(230, 230, 230);
                            // color: white;
                        }

                        td {
                            text-align: left;
                            padding: 10px 5px;

                            &.ngayThang {
                                width: 100px;
                            }

                            &.hoVaTen {
                                // width: 30px;
                                text-transform: capitalize;
                            }

                            &.baoLiXi {
                                width: 80px;
                                text-align: right;
                            }

                            &.preview {
                                width: 30px;
                                color: silver;
                                transition: all 0.3s;
                                cursor: pointer;

                                &:hover {
                                    color: orangered;
                                }
                            }
                        }
                    }
                }

                tfoot {
                    font-weight: 600;

                    tr {
                        border-top: 1px solid silver;

                        td {
                            text-align: left;
                            padding: 10px 5px;

                            &.baoLiXi {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.dark {
    background-color: rgb(55, 55, 55);

    #MyCv {
        header {
            background-color: rgb(50, 50, 50);

            div.content {
                h3 {
                    color: rgb(190, 190, 190);
                }

                button {
                    background-color: rgb(55, 55, 55);
                    border: 1px solid silver;
                    color: rgb(181, 181, 181);

                    i {
                        color: rgb(130, 130, 130);
                    }
                }
            }

            p {
                color: rgb(225, 225, 225);

            }

        }

        main {
            &:hover {
                border-left: 3px solid rgb(45, 45, 45);
            }

            .title {
                h2 {
                    color: rgb(225, 225, 225);
                }
            }

            .info {
                .text {
                    .textFlex {
                        p {
                            color: rgb(225, 225, 225);
                        }

                        h4 {
                            color: rgb(225, 225, 225);

                            a {
                                color: rgb(225, 225, 225);
                                text-decoration: none;

                                &:hover {
                                    color: royalblue;
                                }
                            }
                        }
                    }
                }

                p {
                    color: rgb(225, 225, 225);
                }
            }

            h3 {
                color: rgb(225, 225, 225);
            }

            ul {
                li {
                    color: rgb(225, 225, 225);
                }

                .skills {
                    .text {
                        .textFlex {
                            p {
                                color: rgb(225, 225, 225);
                            }

                            h4 {
                                color: rgb(225, 225, 225);

                                a {
                                    color: rgb(225, 225, 225);
                                    text-decoration: none;

                                    &:hover {
                                        color: royalblue;
                                    }
                                }
                            }
                        }
                    }

                    p {
                        color: rgb(225, 225, 225);
                    }
                }
            }
        }
    }


    .custom-tooltip {
        .ant-tooltip-arrow {
            border-color: silver;
        }
    }

    .flip-container {
        perspective: 1000px;

        .flip-card {
            .flip-card-inner {

                .flip-card-front,
                .flip-card-back {}

                .flip-card-front {
                    color: rgb(190, 190, 190);
                }

                .flip-card-back {
                    color: rgb(190, 190, 190);
                }
            }
        }
    }
}

@media screen and (max-width: 786px) {
    body {
        #MyCv {
            margin: 0 auto;

            //   padding: 0 10px;
            header {
                div.content {
                    width: 100%;
                    margin: 0 auto;
                    padding: 6px 10px;

                    div {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        button {
                            background-color: white;
                            color: orangered;
                            padding: 3px 5px;
                            border: none;
                            border-radius: 6px;
                            font-weight: 600;
                            height: 30px;
                            width: 30px;
                            // line-height: 20px;
                            cursor: pointer;

                            i {
                                transform: rotate(-120deg);
                                color: orangered;
                                // font-size: 16px;
                                font-weight: 600;
                                cursor: pointer;
                                transition: all 0.3s;
                            }
                        }
                    }
                }
            }

            main {
                width: calc(100% - 20px);
                margin: 20px auto;
                padding: 20px 10px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                //   border-radius: 0px 20px 0px 20px;
                border-left: 3px solid transparent;
                transition: all 0.5s;

                &:hover {
                    border-left: 3px solid orangered;
                }

                .title {
                    h2 {
                        font-size: 22px;
                        color: orangered;
                    }
                }

                .info {
                    width: 100%;
                    margin: 20px auto;
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;

                    .photo {
                        width: 100%;
                        height: 200px;
                        border-radius: 8px;
                        display: flex;
                        flex-wrap: wrap;

                        img {
                            width: 200px;
                            height: 200px;
                            border-radius: 8px;
                        }

                        .qrCode {
                            display: none;
                        }
                    }

                    .text {
                        width: 100%;
                        font-size: 20px;

                        .textFlex {
                            margin-top: 5px;
                            margin-bottom: 5px;
                            display: flex;
                            gap: 0px;

                            p.info {
                                width: 30px;
                                margin: 0;

                                i {
                                    font-size: 18px;
                                }
                            }

                            h4 {
                                width: 100%;
                                font-weight: 400;

                                a {
                                    color: black;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    p {
                        font-size: 18px;
                        line-height: 1.8;
                    }
                }

                h3 {
                    width: 100%;
                    font-size: 24px;
                    border-top: 1px solid silver;
                    padding-top: 5px;
                }

                ul {
                    margin-left: 18px;
                }
            }

            footer {
                width: 100%;
            }
        }

        .backToTop {
            bottom: 87px;
            right: 15px;
        }

        .zaloChat {
            bottom: 40px;
            right: 15px;
        }

        .liXi {
            padding: 20px 10px;

            .container {
                width: 100%;

                .listBaoLiXi {
                    // background-color: red;
                    width: 370px !important;
                }
            }

            // #thongBao {
            //   width: 100%;
            //   &.show {
            //     top: -500px;
            //     margin: 0 auto;
            //   }
            // }
            .danhSach {
                table {
                    td.loiChuc {
                        display: none;
                    }
                }
            }
        }
    }

    body.dark {
        background-color: rgb(55, 55, 55);

        #app {
            header {
                background-color: rgb(50, 50, 50);

                div {
                    button {
                        background-color: rgb(55, 55, 55);
                        border: 1px solid silver;

                        color: silver;

                        i {
                            color: silver;
                        }
                    }
                }

                p {
                    color: silver;
                }
            }

            main {
                &:hover {
                    border-left: 3px solid rgb(45, 45, 45);
                }

                .title {
                    h2 {
                        color: rgb(240, 240, 240);
                    }
                }

                .info {
                    .text {
                        .textFlex {
                            p {
                                color: silver;
                            }

                            h4 {
                                color: silver;

                                a {
                                    color: silver;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    p {
                        color: silver;
                    }
                }

                h3 {
                    color: silver;
                }

                ul {
                    li {
                        color: silver;
                    }

                    .skills {
                        .text {
                            .textFlex {
                                p {
                                    color: silver;
                                }

                                h4 {
                                    color: silver;

                                    a {
                                        color: silver;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }

                        p {
                            color: silver;
                        }
                    }
                }
            }
        }
    }
}

@keyframes zaloChatAnimation {
    0% {
        transform: scale(1);
        background-color: #015bd1d6;
    }

    50% {
        transform: scale(1.2);
        background-color: rgba(255, 68, 0, 0.8);
    }

    100% {
        background-color: #015bd1d6;
        transform: scale(1);
    }
}

/* CSS */
@keyframes flipAnimation {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0.5;
        transform: translateY(5px);
    }

    50% {
        opacity: 1;
        transform: translateY(-5px);
    }

    100% {
        opacity: 0;
        transform: translateY(-5px);
    }
}

@keyframes wave {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes blink {
    0% {
        opacity: 1;
        color: red;
    }

    50% {
        opacity: 0.5;
        color: orangered;
    }

    100% {
        opacity: 1;
        color: red;
    }
}

@media print {
    body {
        background-color: white !important;
        margin: 0 5px;

        #MyCv {
            background-color: white !important;

            main {
                width: 99%;
                box-shadow: none;
                background-color: white !important;
                border-bottom: 1px solid silver;

                h2 {
                    font-size: 34px !important;
                }

                p,
                h4 {
                    font-size: 24px !important;
                }
            }

            footer {
                span {
                    b.luotTruyCap {
                        display: none !important;
                    }
                }
            }
        }
    }

    body.dark {
        * {
            color: black !important;
        }
    }

    header,
    .zaloChat,
    .backToTop {
        display: none;
    }
}