* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

html {
    scroll-behavior: smooth;
}


#container.dark {
    background-color: rgb(50, 50, 50);

    .megaMenu {
        background-color: rgb(35, 35, 35);
    }


    #home {
        .content {
            .homeIcon {
                i {
                    color: rgb(60, 60, 60);

                }
            }

            .homeItem {
                ul {
                    li {
                        color: rgb(235, 235, 235);
                    }
                }
            }
        }
    }


    #service {
        h1 {
            color: rgb(235, 235, 235);
        }

        background-color: rgb(45, 45, 45);

        .content {

            .fontend,
            .backend {
                background-color: rgb(45, 45, 45);
                box-shadow: none;

                ul {
                    li {
                        color: rgb(235, 235, 235);
                    }
                }




                .bgc {
                    i {
                        color: rgb(60, 60, 60);
                    }
                }

            }
        }

    }

    #ourTeam {
        h1 {
            color: rgb(235, 235, 235);
        }

        .content {
            .team {
                background-color: rgb(60, 60, 60);

                .button {
                    i {
                        background-color: rgb(45, 45, 45);

                        &:hover {
                            color: white;
                        }

                    }


                }

            }
        }



    }

    #chatForm {
        h1 {
            color: rgb(235, 235, 235);

        }

        .content {
            .textMessage {
                background-color: transparent;

                .text {

                    p {
                        background-color: rgb(60, 60, 60);
                        color: rgb(235, 235, 235);
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                    }
                }
            }

            form {
                textarea {
                    background-color: transparent;
                    color: rgb(235, 235, 235);

                }
            }
        }
    }

    #contact {

        .content {
            .contactItem {
                p {
                    color: rgb(235, 235, 235);

                    a {
                        color: rgb(235, 235, 235);
                    }
                }
            }

            form {
                .inputItem {
                    input {
                        background-color: transparent;
                        color: rgb(235, 235, 235);

                    }

                    textarea {
                        background-color: transparent;
                        color: rgb(235, 235, 235);
                    }

                }
            }
        }
    }

    #test {
        .content {
            .testItem {
                p {
                    color: rgb(235, 235, 235);

                    a {
                        color: rgb(235, 235, 235);
                    }
                }
            }

            form {
                .inputItem {
                    input {
                        background-color: transparent;
                        color: rgb(235, 235, 235);

                    }
                }
            }
        }
    }

}

#container {
    overflow-x: hidden;

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(35, 35, 35);
        overflow-x: hidden;




        .icon {
            position: absolute;
            top: 27px;
            left: 40px;
            // width: 100%;
            z-index: 2000;
            // display: none;

            i {
                color: white;
                font-size: 20px;
                cursor: pointer;

            }

        }


        .circle {
            width: 80px;
            height: 80px;
            background-color: rgb(242, 112, 13);
            border-radius: 50%;
            animation: moveX 5s infinite;
            // transition: all .5s;
        }

        button {
            border: none;
            width: 30px;
            height: 30px;
            text-align: center;
            // line-height: 30px;
            position: fixed;
            top: 25px;
            right: 15px;
            border-radius: 50%;
            cursor: pointer;
            background-color: transparent;

            i {
                font-size: 22px;
                color: rgb(242, 112, 13);
            }
        }


        ul {
            display: flex;
            gap: 30px;
            margin-right: 80px;

            li {
                list-style: none;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;

                &::after {
                    content: '';
                    height: 2px;
                    width: 0;
                    background-color: red;
                    display: block;
                    transition: all .3s;
                }

                &:hover {
                    a {
                        color: red;
                    }

                    &::after {
                        width: 100%;
                    }
                }

                a {
                    text-decoration: none;
                    color: rgb(242, 112, 13);
                }


            }

        }
    }


    .megaMenu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(242, 112, 13);
        z-index: 3000;
        height: 100vh;
        transition: all .3s;
        transform: translateY(100%);
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // flex-direction: column;

        .megaIcon {
            position: fixed;
            top: 27px;
            left: 40px;
            width: 100%;
            z-index: 2000;
            cursor: pointer;


            i {

                &.close {
                    color: white;
                    font-size: 22px;


                }

                &.opacity {
                    animation: opacityX 2s;

                }
            }
        }

        ul {
            margin-top: 60px;
            // display: none;

            &.opacity {
                animation: opacityX 1s;
            }

            li {
                margin-left: 40px;
                font-size: 24px;
                list-style: none;

                padding: 5px 0;

                i {
                    color: white;
                }

                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }


    }

    #home {
        padding-top: 80px;
        // padding: 200px 100px;
        height: 100vh;
        // background-image: url('../public/img/banner4.jpg');
        background-size: cover;
        /* hình ảnh sẽ tự động được phóng to hoặc thu nhỏ để vừa với phần tử */
        background-position: center;
        /* căn giữa hình ảnh */
        background-repeat: no-repeat;
        // filter: grayscale(1);

        // display: flex;

        // align-items: center;
        // justify-content: flex-end;

        .content {
            padding: 100px;
            height: calc(100vh - 80px);
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 20px;
            overflow-x: hidden;
            flex-wrap: wrap;


            .homeIcon {
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 400px;
                    color: rgba(240, 245, 255, 0.8);
                    animation: moveX 3s infinite;

                }


            }


            .homeItem {

                animation: moveFY 1.5s ease-out;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: space-between;

                // height: 340px;

                h1 {
                    padding: 10px;
                    font-weight: 400;
                    // color: rgb(120, 120, 120);
                    color: rgb(242, 112, 13);

                    i {
                        font-size: 130px;
                    }

                }

                p {
                    padding: 10px 20px;
                    font-size: 26px;
                    font-weight: 300;
                    width: 50%;
                    text-align: right;
                    color: rgb(120, 120, 120);

                }

                ul {
                    padding: 0 20px;

                    li {
                        font-size: 36px;
                        font-weight: 300;
                        list-style: circle;
                        color: rgb(120, 120, 120);

                    }
                }

                button {
                    padding: 10px;
                    border: none;
                    font-weight: 400;
                    background-color: rgb(242, 112, 13);
                    color: white;
                    cursor: pointer;
                    margin: 30px 20px 0 0;
                    transition: all .5s;
                    animation: moveFY 4s;


                    &:hover {
                        background-color: red;

                    }

                    a {
                        text-decoration: none;
                        color: white;
                        font-size: 18px;

                    }

                    i {
                        font-size: 16px;

                    }
                }
            }



        }


    }



    #service {
        // margin-top: 100px;
        padding: 100px 100px;
        background-color: rgb(255, 252, 252);



        h1 {
            margin: 0 auto 20px;
            color: rgb(35, 35, 35);

        }



        .content {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            // justify-content: space-around;
            // padding: 5px;


            .fontend {
                width: calc(50% - 10px);
                background-color: white;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 20px;
                position: relative;
                overflow: hidden;

                h2 {
                    color: rgb(242, 112, 13);
                    position: relative;
                    z-index: 2;


                    i {
                        color: rgb(242, 112, 13);
                    }
                }

                ul {
                    margin: 10px 0 0 37px;
                    position: relative;
                    z-index: 2;

                    li {
                        color: rgb(120, 120, 120);
                        padding: 10px 0;
                        font-size: 18px;
                    }
                }

                .bgc {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 20px;
                    z-index: 1;

                    i {
                        color: rgba(240, 245, 255, 0.8);
                        font-size: 160px;
                        animation: moveX 3s infinite;

                    }
                }

            }

            .backend {
                width: calc(50% - 10px);
                background-color: white;

                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 20px;

                position: relative;
                overflow: hidden;


                h2 {
                    color: rgb(242, 112, 13);
                    position: relative;
                    z-index: 2;


                    i {
                        color: rgb(242, 112, 13);
                    }
                }

                ul {
                    margin: 10px 0 0 37px;
                    position: relative;
                    z-index: 2;


                    li {
                        color: rgb(120, 120, 120);
                        padding: 10px 0;
                        font-size: 18px;
                    }
                }

                .bgc {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 20px;
                    z-index: 1;

                    i {
                        color: rgba(240, 245, 255, 0.8);
                        font-size: 160px;
                        animation: moveX 3s infinite;


                    }
                }
            }



        }

    }

    .moveFX {
        animation: moveFX;
        animation-duration: 1s;
    }

    .moveLFX {
        animation: moveLFX;
        animation-duration: 1s;
    }


    #ourTeam {
        padding: 100px;

        h1 {
            margin-bottom: 30px;
            color: rgb(35, 35, 35);

        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            flex-wrap: wrap;

            .team {
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: 10px;
                padding: 20px;
                background-color: whitesmoke;
                // cursor: pointer;
                transition: all .5s;


                &:hover {
                    scale: 1.1;
                    border-radius: 20px;
                    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                }

                img {
                    width: 260px;
                    height: 260px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                    cursor: pointer;


                }

                h3 {
                    color: rgb(242, 112, 13);
                    font-weight: 400;
                    text-transform: capitalize;
                }

                p {
                    // color: rgb(120, 120, 120);
                    color: rgb(200, 200, 200);
                    text-transform: capitalize;


                }

                .button {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;

                    i {
                        // width: 20px;
                        text-align: center;
                        color: rgb(120, 120, 120);
                        transition: all .3s;
                        width: 36px;
                        height: 36px;
                        line-height: 36px;
                        background-color: rgb(255, 255, 255);
                        border-radius: 50%;

                        &:hover {
                            color: red;
                            // background-color: rgb(230, 230, 230);

                            // scale: 1.2;
                        }
                    }
                }

            }


        }

        button {
            margin: 40px auto 0;
            padding: 10px 20px;
            background-color: rgb(242, 112, 13);
            border: none;
            color: white;
            font-size: 18px;
            cursor: pointer;
            transition: all .5s;

            &:hover {
                background-color: red;
            }

            a {
                text-decoration: none;
                color: white;
            }
        }



    }


    #video {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 100px;

        video {
            width: 60%;
        }
    }

    #chatForm {
        padding: 100px;

        h1 {
            text-align: center;
            margin: 20px auto;
        }

        .content {
            .textMessage {
                margin-bottom: 20px;
                padding: 10px;
                // background-color: whitesmoke;
                height: 300px;
                overflow-y: scroll;
                border: 1px solid silver;
                // display: flex;
                // flex-direction: column;

                -ms-overflow-style: none;
                /* IE và Edge */
                scrollbar-width: none;
                /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                .text {
                    display: flex;



                    p {
                        padding: 6px 12px;
                        display: inline;
                        max-width: 60%;
                        margin-bottom: 10px;
                        background-color: rgb(240, 240, 240);
                        border-radius: 5px;
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                        span {

                            i {
                                font-size: 14px;
                                // display: block;
                            }
                        }
                    }
                }

            }

            form {
                textarea {
                    width: 100%;
                    padding: 5px;
                    font-size: 16px;
                    resize: none;
                    border: 1px solid silver;


                }

                button {
                    margin-top: 10px;
                    padding: 5px 20px;
                    font-size: 18px;
                    border: none;
                    background-color: rgb(242, 112, 13);
                    color: white;

                }
            }
        }
    }

    #contact {
        padding: 100px 100px 100px;


        .content {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;
            overflow-x: hidden;


            .contactItem {
                display: flex;
                gap: 1px;
                flex-direction: column;

                .contactIcon {
                    width: 100px;
                    height: 100px;
                    text-align: center;
                    line-height: 100px;
                    background-color: rgb(35, 35, 35);
                    color: rgb(235, 235, 235);
                    cursor: pointer;

                    a {
                        color: rgb(235, 235, 235);

                    }

                    i {
                        font-size: 26px;
                    }
                }

                p {
                    color: rgb(35, 35, 35);
                    padding: 10px 0;
                    font-size: 18px;

                    i {
                        width: 20px;
                        text-align: center;
                        color: rgb(120, 120, 120);


                    }

                    a {
                        text-decoration: none;
                        color: rgb(35, 35, 35);


                    }
                }

                iframe {
                    height: 302px;
                    width: 604px;
                    border: none;
                }
            }



            form {
                // background-color: red;
                width: 100%;

                // padding: 10px;
                margin-bottom: 20px;

                h2 {
                    background-color: rgb(242, 112, 13);
                    color: white;
                    text-align: center;
                    height: 34px;
                    line-height: 34px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }

                .inputItem {
                    position: relative;

                    input {
                        width: 100%;
                        margin: 0 auto 5px;
                        height: 34px;
                        padding: 0 5px 0 30px;
                        font-size: 16px;
                        outline: none;
                        border: 1px solid silver;
                    }

                    i {
                        width: 20px;
                        text-align: center;
                        position: absolute;
                        top: 9px;
                        left: 8px;
                        font-size: 16px;
                        color: rgb(120, 120, 120);
                    }

                    textarea {
                        padding: 5px;
                        width: 100%;
                        resize: none;
                        height: calc(3 * 26px);
                        /* 3 lines * line-height */
                        line-height: 20px;
                        /* Adjust line-height as needed */
                        font-size: 16px;
                        outline: none;
                        border: 1px solid silver;




                    }



                }

                button {
                    padding: 8px 15px;
                    border: none;
                    background-color: rgb(242, 112, 13);
                    color: white;
                    font-size: 18px;
                    cursor: pointer;
                    transition: all .5s;

                    &:hover {
                        background-color: red;
                    }
                }
            }
        }
    }

    #test {
        padding: 100px 100px 100px;


        .content {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;
            overflow-x: hidden;


            .testItem {
                display: flex;
                gap: 1px;
                flex-direction: column;

                .testIcon {
                    width: 100px;
                    height: 100px;
                    text-align: center;
                    line-height: 100px;
                    background-color: rgb(35, 35, 35);
                    color: rgb(235, 235, 235);
                    cursor: pointer;

                    a {
                        color: rgb(235, 235, 235);

                    }

                    i {
                        font-size: 26px;
                    }
                }

                p {
                    color: rgb(35, 35, 35);
                    padding: 10px 0;
                    font-size: 18px;

                    i {
                        width: 20px;
                        text-align: center;
                        color: rgb(120, 120, 120);


                    }

                    a {
                        text-decoration: none;
                        color: rgb(35, 35, 35);


                    }
                }

            }



            form {
                // background-color: red;
                width: 100%;

                // padding: 10px;
                margin-bottom: 20px;

                h2 {
                    background-color: rgb(242, 112, 13);
                    color: white;
                    text-align: center;
                    height: 34px;
                    line-height: 34px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }

                .inputItem {
                    position: relative;

                    input {
                        width: 100%;
                        margin: 0 auto 5px;
                        height: 34px;
                        padding: 0 5px 0 30px;
                        font-size: 16px;
                        outline: none;
                        border: 1px solid silver;
                    }

                    i {
                        width: 20px;
                        text-align: center;
                        position: absolute;
                        top: 9px;
                        left: 8px;
                        font-size: 16px;
                        color: rgb(120, 120, 120);
                    }



                }

                button {
                    padding: 8px 15px;
                    border: none;
                    background-color: rgb(242, 112, 13);
                    color: white;
                    font-size: 18px;
                    cursor: pointer;
                    transition: all .5s;

                    &:hover {
                        background-color: red;
                    }
                }
            }
        }
    }

    footer {
        height: 50px;
        text-align: center;
        line-height: 50px;
        background-color: rgb(35, 35, 35);
        color: rgb(120, 120, 120);

        a {
            text-decoration: none;
            color: rgb(120, 120, 120);

        }


    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: white;
            font-size: 22px;
            padding: 0 20px;
            text-align: center;
        }

    }

    .scaleX {
        animation: scaleX 2s;

    }

    .scaleY {
        animation: scaleY 2s;

    }

    .backToTop {
        position: fixed;
        bottom: 60px;
        right: 20px;
        z-index: 2000;

        button {
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background-color: rgba(255, 100, 100, 0.4);
            border: none;
            border-radius: 10px;
            cursor: pointer;

            i {
                color: white;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    #container {


        .menu {
            .icon {
                display: block;
            }

            ul {
                display: none;

            }


        }

        .megaMenu {
            overflow: hidden;

            ul {
                display: block;
            }
        }

        #home {
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 80px);



            .content {
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .homeIcon {

                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    padding: 20px;


                    i {
                        font-size: 300px;
                    }
                }

                .homeItem {

                    position: absolute;
                    // top: 50%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    padding: 20px;
                    transition: all 3s;

                    h1 {
                        i {
                            font-size: 60px;
                        }
                    }


                    ul {
                        li {
                            font-size: 26px;
                        }
                    }
                }
            }
        }

        #service {
            padding: 100px 5px 100px;

            .content {
                flex-wrap: wrap;
                gap: 10px;

                .fontend,
                .backend {
                    padding: 10px;
                    width: 100%;
                }

                .moveFX {
                    animation: moveFX;
                    animation-duration: 2s;
                }

                .moveLFX {
                    animation: moveLFX;
                    animation-duration: 2s;
                }

            }
        }

        #ourTeam {
            padding: 100px 5px;


        }

        #video {
            padding: 0;

            video {
                width: 100%;
            }
        }


        #chatForm {
            padding: 100px 5px 20px;
        }


        #contact {
            padding: 100px 0px 100px;

            .content {
                flex-direction: column-reverse;
                // gap: 30px;

                .contactItem {
                    width: 100%;
                    padding: 0;

                    &:first-child {
                        flex-direction: row;
                        justify-content: space-around;
                        padding: 0 20px;
                    }

                    p {
                        padding: 10px;
                        font-size: 18px;
                    }

                    iframe {
                        width: 100%;
                    }



                }

                form {
                    padding: 0 10px;
                }

                map {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .backToTop {
            right: 10px;
        }



    }
}

@keyframes moveX {
    0% {
        transform: translateX(-10px);

    }

    50% {
        transform: translateX(10px);

    }

    100% {
        transform: translateX(-10px);

    }
}

@keyframes moveFX {
    0% {
        transform: translateX(100%);
        opacity: 0;

    }

    // 50% {
    //     transform: translateX(50%);
    //     opacity: .5;

    // }


    100% {
        transform: translateX(0);
        opacity: 1;

    }
}

@keyframes moveLFX {
    0% {
        transform: translateX(-100%);
        opacity: 0;

    }

    // 50% {
    //     transform: translateX(-50%);
    //     opacity: .5;

    // }


    100% {
        transform: translateX(0);
        opacity: 1;

    }
}

@keyframes moveY {
    0% {
        transform: translateY(-50px);

    }

    50% {
        transform: translateY(-50px);

    }

    100% {
        transform: translateY(0);

    }
}

@keyframes moveFY {
    0% {
        transform: translateY(100%);
        opacity: 0;

    }

    50% {
        transform: translateY(100%);
        opacity: 0;

    }


    100% {
        transform: translateY(0);
        opacity: 1;

    }
}

@keyframes opacityX {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }



}

@keyframes scaleX {

    0% {
        opacity: 0;
        scale: .5;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }

    50% {
        opacity: .5;
        scale: 1.1;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;


    }

    100% {
        opacity: 1;
        scale: 1;

    }



}

@keyframes scaleY {
    0% {
        opacity: 1;
        scale: 1.1;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;


    }

    50% {
        opacity: .5;
        scale: .5;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }



    100% {
        opacity: 1;
        scale: 1;

    }



}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}